<template>
    <p>Logging in.....</p>
</template>

<script>
export default {
    name:"OtpVerify",
    data(){
      return {
        otp:"",
        redirect_path: "",
        redirect_booking_id: "",
      }
    },
    created(){
      this.redirect_path = localStorage.getItem('redirect_path');
      this.redirect_booking_id =  localStorage.getItem('redirect_booking_id');

      if(this.$route.query.token) {
          localStorage.token = this.$route.query.token;
          this.toast.success("Logged in successfully");

          if (this.redirect_booking_id) {
            this.$router.replace(`/pages/${this.redirect_path}?booking_id=${this.redirect_booking_id}`);
          } else {
            this.$router.replace("/pages/dashboard");
          }

      } else {
        this.$router.replace("?error=Invalid Token");
      }
    },
    methods:{

    }
}
</script>

<style scoped>

</style>